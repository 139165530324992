const copyToClipboard = (url) => {
  if (!navigator.clipboard) {
    return ''
  }

  return navigator.clipboard.writeText(url)
}

export const stringToBoolean = (str) => {
  if (str.toLowerCase() === 'true') {
    return true;
  } else {
    return false;
  }
}

export default {
  copyToClipboard
}